import React, { useState, useEffect } from 'react';
import LandingPage from './components/ui/LandingPage';
import PrivacyPolicy from './components/ui/PrivacyPolicy';
import TermsOfService from './components/ui/TermsOfService';
import Footer from './components/ui/Footer';
import './App.css';

function App() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    // Listen for popstate events (back/forward navigation)
    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);

  // Simple routing function
  const renderPage = () => {
    switch (currentPath) {
      case '/privacy-policy':
        return <PrivacyPolicy />;
      case '/terms-of-service':
        return <TermsOfService />;
      default:
        return <LandingPage />;
    }
  };

  return (
    <div className="app">
      {renderPage()}
      <Footer />
    </div>
  );
}

export default App;