import React, { useEffect, useRef } from 'react';
import './LandingPage.css'; // Reuse the same CSS

const PrivacyPolicy: React.FC = () => {
  const headerRef = useRef<HTMLElement>(null);
  const mainRef = useRef<HTMLElement>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observe header and main
    if (headerRef.current) observer.observe(headerRef.current);
    if (mainRef.current) observer.observe(mainRef.current);

    // Observe all policy sections
    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  // SEO metadata
  const pageTitle = "Privacy Policy | Forcify AI";
  const pageDescription = "Forcify AI's privacy policy outlines how we collect, use, and protect your personal information when you use our AI agent platform.";
  const pageKeywords = "privacy policy, data protection, Forcify AI, personal data, GDPR, data security";

  // Set document title and meta tags for SEO
  useEffect(() => {
    // Set document title
    document.title = pageTitle;
    
    // Update meta tags
    const metaTags = [
      { name: 'description', content: pageDescription },
      { name: 'keywords', content: pageKeywords },
      { property: 'og:title', content: pageTitle },
      { property: 'og:description', content: pageDescription },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://forcify.ai/privacy-policy' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:title', content: pageTitle },
      { name: 'twitter:description', content: pageDescription }
    ];
    
    // Remove existing meta tags
    document.querySelectorAll('meta').forEach(tag => {
      if (tag.getAttribute('name') === 'viewport') return; // Keep viewport meta
      if (tag.getAttribute('name') === 'theme-color') return; // Keep theme-color meta
      if (tag.getAttribute('charset')) return; // Keep charset meta
      tag.remove();
    });
    
    // Add new meta tags
    metaTags.forEach(({ name, property, content }) => {
      const meta = document.createElement('meta');
      if (name) meta.setAttribute('name', name);
      if (property) meta.setAttribute('property', property);
      meta.setAttribute('content', content);
      document.head.appendChild(meta);
    });
    
    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', 'https://forcify.ai/privacy-policy');
    
  }, [pageTitle, pageDescription, pageKeywords]);

  return (
    <div className="landing-page">
      <header className="policy-header" ref={headerRef}>
        <a href="/">
          <img src="/logo.png" alt="Forcify.ai Logo" className="policy-logo" />
        </a>
      </header>

      <main className="policy-main" ref={mainRef}>
        <div className="policy-container">
          <h1>Privacy Policy</h1>
          <p className="last-updated">Last Updated: March 6, 2025</p>
        </div>
      </main>

      <div className="policy-container">
        <div className="policy-section" ref={el => sectionRefs.current[0] = el}>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Forcify AI ("we," "our," or "us"). We respect your privacy and are committed to protecting your personal data. 
            This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[1] = el}>
          <h2>2. Data We Collect</h2>
          <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
          <ul>
            <li><strong>Identity Data</strong> includes first name, last name, username or similar identifier.</li>
            <li><strong>Contact Data</strong> includes email address and telephone numbers.</li>
            <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
            <li><strong>Usage Data</strong> includes information about how you use our website and services.</li>
            <li><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
          </ul>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[2] = el}>
          <h2>3. How We Use Your Data</h2>
          <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
          <ul>
            <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
            <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
            <li>Where we need to comply with a legal obligation.</li>
            <li>To provide you with information about our services that you request from us.</li>
            <li>To notify you about changes to our service.</li>
            <li>To improve our website and services.</li>
          </ul>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[3] = el}>
          <h2>4. Data Security</h2>
          <p>
            We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. 
            In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. 
            They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[4] = el}>
          <h2>5. Data Retention</h2>
          <p>
            We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected it for, 
            including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. 
            We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[5] = el}>
          <h2>6. Your Legal Rights</h2>
          <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:</p>
          <ul>
            <li>Request access to your personal data.</li>
            <li>Request correction of your personal data.</li>
            <li>Request erasure of your personal data.</li>
            <li>Object to processing of your personal data.</li>
            <li>Request restriction of processing your personal data.</li>
            <li>Request transfer of your personal data.</li>
            <li>Right to withdraw consent.</li>
          </ul>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[6] = el}>
          <h2>7. Cookies</h2>
          <p>
            We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. 
            Cookies are files with a small amount of data which may include an anonymous unique identifier. 
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[7] = el}>
          <h2>8. Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "last updated" date at the top of this Privacy Policy.
            You are advised to review this Privacy Policy periodically for any changes.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[8] = el}>
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at:
            <br />
            <a href="mailto:privacy@forcify.ai">privacy@forcify.ai</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 