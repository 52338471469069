import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="site-footer">
      <div className="footer-main">
        <p>© 2024-2025 Forcify AI - All rights reserved.</p>
        <p>Developed with excellence by <a href="https://www.ainovasystems.com/" target="_blank" rel="noopener noreferrer">Ainova Systems</a> • Enterprise Architecture & AI Solutions</p>
        <nav>
          <a href="/">Home</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-of-service">Terms of Service</a>
          <a href="/sitemap.xml">Sitemap</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer; 