import React, { useState, useEffect, FormEvent } from 'react';
import { Button, Input } from './UIComponents';
import { CloudIcon, Cpu, Users, CodeIcon } from 'lucide-react';
import './LandingPage.css';

const LandingPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [visibleSections, setVisibleSections] = useState<string[]>([]);
  const [isHeroVisible, setIsHeroVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  // SEO metadata
  const pageTitle = "Forcify AI - Your Workforce, Amplified | AI Agents for Business";
  const pageDescription = "Forcify AI provides intelligent AI agents for lead generation, marketing, HR, competitor tracking, and customer support to amplify your workforce capabilities.";
  const pageKeywords = "AI agents, business automation, lead generation, marketing automation, HR automation, AI workforce";

  // Set document title and meta tags for SEO
  useEffect(() => {
    // Set document title
    document.title = pageTitle;
    
    // Update meta tags
    const metaTags = [
      { name: 'description', content: pageDescription },
      { name: 'keywords', content: pageKeywords },
      { property: 'og:title', content: pageTitle },
      { property: 'og:description', content: pageDescription },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://forcify.ai' },
      { property: 'og:image', content: '/og-image.png' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: pageTitle },
      { name: 'twitter:description', content: pageDescription }
    ];
    
    // Remove existing meta tags
    document.querySelectorAll('meta').forEach(tag => {
      if (tag.getAttribute('name') === 'viewport') return; // Keep viewport meta
      if (tag.getAttribute('name') === 'theme-color') return; // Keep theme-color meta
      if (tag.getAttribute('charset')) return; // Keep charset meta
      tag.remove();
    });
    
    // Add new meta tags
    metaTags.forEach(({ name, property, content }) => {
      const meta = document.createElement('meta');
      if (name) meta.setAttribute('name', name);
      if (property) meta.setAttribute('property', property);
      meta.setAttribute('content', content);
      document.head.appendChild(meta);
    });
    
    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', 'https://forcify.ai');
    
    return () => {
      // Cleanup function not needed for meta tags as they will be updated on next render
    };
  }, [pageTitle, pageDescription, pageKeywords]);

  useEffect(() => {
    setIsHeroVisible(true);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50); // Hide indicator after 50px of scroll

      const sections = document.querySelectorAll('section');
      const newVisibleSections: string[] = [];

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight * 0.75 && rect.bottom >= 0) {
          newVisibleSections.push(section.id);
        }
      });

      setVisibleSections(newVisibleSections);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to check initial state

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await fetch('https://hook.eu2.make.com/dfy6f7u2mbu489sxbtdmz73ebc8u7cnr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: "forcify",
          email: email,
          name: name
        }),
      });

      if (response.ok) {
        setSubmitMessage('Thank you for subscribing!');
        setEmail('');
        setName('');
      } else {
        setSubmitMessage('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="landing-page">
      <section id="hero" className="hero-section">
        <div className="hero-content">
          <img src="/logo.png" alt="Forcify.ai Logo" className="logo" width="150" height="150" />

          <div className={`company-info ${isHeroVisible ? 'visible' : ''}`}>
            <h1 className="company-name">FORCIFY AI</h1>
            <p className="company-tagline">Your Workforce, Amplified</p>
          </div>

          <div className={`main-tagline ${isHeroVisible ? 'visible' : ''}`}>
            <h2>
              <span className="tagline-part tagline-part-1">Hire Intelligence,</span>
              <br />
              <span className="tagline-part tagline-part-2">Hire AI</span>
            </h2>
          </div>
        </div>
        
        <div className={`scroll-indicator ${isHeroVisible && !isScrolled ? 'visible' : 'hidden'}`} aria-hidden="true">
          <span className="scroll-indicator-text">Scroll Down</span>
          <div className="scroll-indicator-arrow"></div>
        </div>
      </section>

      <section id="features" className={`section ${visibleSections.includes('features') ? 'visible' : 'hidden'}`}>
        <h2 className="section-title">Empower Your Business with AI</h2>
        <div className="feature-grid">
          <FeatureCard
            title="Lead Generation and Sales"
            description="AI agents scrape publicly available data for potential leads based on predefined criteria, then crafts personalized outreach messages."
          />
          <FeatureCard
            title="Marketing"
            description="AI agents generate social media content calendars and personalize email campaigns based on customer behavior and engagement metrics."
          />
          <FeatureCard
            title="HR and Recruitment"
            description="AI agents screen resumes, flag top applicants, and manage employee onboarding processes with automated scheduling and personalized materials."
          />
          <FeatureCard
            title="Competitor Tracking"
            description="AI agents monitor competitor activities, alert on product launches or pricing changes, and generate weekly reports on market positioning and sentiment."
          />
          <FeatureCard
            title="Customer Support"
            description="AI agents handle initial inquiries, route complex cases, and review support tickets to categorize and suggest solutions based on historical data."
          />
          <FeatureCard
            title="Customer Experience"
            description="AI agents analyze customer interactions to create journey maps, identify improvement opportunities, and predict potential churners for retention campaigns."
          />
        </div>
      </section>

      <section id="platform" className={`section ${visibleSections.includes('platform') ? 'visible' : 'hidden'}`}>
        <div className="platform-section">
          <h2 className="section-title">Revolutionize Your Workflow</h2>
          <p>Our platform brings you a cutting-edge AI Automation Marketplace.</p>
          <div className="platform-features">
            <Feature
              icon={<CloudIcon size={48} />}
              title="AI Marketplace"
              description="Access pre-built AI agents for various business needs"
            />
            <Feature
              icon={<Users size={48} />}
              title="Seamless Integration"
              description="Integrate AI agents into your existing team structure with popular communication tools like Slack and Telegram"
            />
            <Feature
              icon={<Cpu size={48} />}
              title="Task Automations"
              description="Create and configure AI Agents for task automations tailored to your requirements"
            />
            <Feature
              icon={<CodeIcon size={48} />}
              title="Developer Marketplace"
              description="Developers can train, develop and sell predefined AI agents in our marketplace"
            />
          </div>
        </div>
      </section>

      <section id="contact" className={`section ${visibleSections.includes('contact') ? 'visible' : 'hidden'}`}>
        <div className="contact-section">
          <h2 className="section-title">Join the AI Revolution</h2>
          <p>
          Be the first to know when we launch and get exclusive early access:
          </p>
          <form onSubmit={handleSubmit} className="contact-form" aria-label="Newsletter signup">
            <Input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              required
              disabled={isSubmitting}
            />
            <Input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              required
              disabled={isSubmitting}
            />
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Join Waiting List'}
            </Button>
          </form>
          {submitMessage && <p className="submit-message">{submitMessage}</p>}
          <p className="contact-feedback">Please share your feedback by emailing us at <a href="mailto:requests@forcify.ai">requests@forcify.ai</a></p>
        </div>        
      </section>
    </div>
  );
};

interface FeatureCardProps {
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description }) => (
  <div className="feature-card">
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

interface FeatureProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => (
  <div className="platform-feature">
    <div className="platform-feature-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

export default LandingPage;