import React, { useEffect, useRef } from 'react';
import './LandingPage.css'; // Reuse the same CSS

const TermsOfService: React.FC = () => {
  const headerRef = useRef<HTMLElement>(null);
  const mainRef = useRef<HTMLElement>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observe header and main
    if (headerRef.current) observer.observe(headerRef.current);
    if (mainRef.current) observer.observe(mainRef.current);

    // Observe all policy sections
    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  // SEO metadata
  const pageTitle = "Terms of Service | Forcify AI";
  const pageDescription = "Forcify AI's terms of service outline the rules, guidelines, and legal agreements between users and our AI agent platform.";
  const pageKeywords = "terms of service, legal agreement, Forcify AI, user agreement, terms and conditions, service terms";

  // Set document title and meta tags for SEO
  useEffect(() => {
    // Set document title
    document.title = pageTitle;
    
    // Update meta tags
    const metaTags = [
      { name: 'description', content: pageDescription },
      { name: 'keywords', content: pageKeywords },
      { property: 'og:title', content: pageTitle },
      { property: 'og:description', content: pageDescription },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://forcify.ai/terms-of-service' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:title', content: pageTitle },
      { name: 'twitter:description', content: pageDescription }
    ];
    
    // Remove existing meta tags
    document.querySelectorAll('meta').forEach(tag => {
      if (tag.getAttribute('name') === 'viewport') return; // Keep viewport meta
      if (tag.getAttribute('name') === 'theme-color') return; // Keep theme-color meta
      if (tag.getAttribute('charset')) return; // Keep charset meta
      tag.remove();
    });
    
    // Add new meta tags
    metaTags.forEach(({ name, property, content }) => {
      const meta = document.createElement('meta');
      if (name) meta.setAttribute('name', name);
      if (property) meta.setAttribute('property', property);
      meta.setAttribute('content', content);
      document.head.appendChild(meta);
    });
    
    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', 'https://forcify.ai/terms-of-service');
    
  }, [pageTitle, pageDescription, pageKeywords]);

  return (
    <div className="landing-page">
      <header className="policy-header" ref={headerRef}>
        <a href="/">
          <img src="/logo.png" alt="Forcify.ai Logo" className="policy-logo" />
        </a>
      </header>

      <main className="policy-main" ref={mainRef}>
        <div className="policy-container">
          <h1>Terms of Service</h1>
          <p className="last-updated">Last Updated: March 6, 2025</p>
        </div>
      </main>

      <div className="policy-container">
        <div className="policy-section" ref={el => sectionRefs.current[0] = el}>
          <h2>1. Acceptance of Terms</h2>
          <p>
            Welcome to Forcify AI. By accessing or using our website, services, or platform, you agree to be bound by these Terms of Service ("Terms"). 
            If you do not agree to these Terms, please do not use our services.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[1] = el}>
          <h2>2. Description of Services</h2>
          <p>
            Forcify AI provides an AI agent platform that enables businesses to automate various tasks including but not limited to lead generation, 
            marketing, HR functions, competitor tracking, and customer support. Our platform includes:
          </p>
          <ul>
            <li>Access to pre-built AI agents for various business needs</li>
            <li>Integration capabilities with popular communication tools</li>
            <li>Task automation configuration tools</li>
            <li>Developer marketplace for custom AI agent development</li>
          </ul>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[2] = el}>
          <h2>3. User Accounts</h2>
          <p>
            To access certain features of our platform, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
          </p>
          <p>
            You are responsible for safeguarding your password and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[3] = el}>
          <h2>4. User Conduct</h2>
          <p>You agree not to use our services to:</p>
          <ul>
            <li>Violate any applicable laws or regulations</li>
            <li>Infringe the intellectual property rights of others</li>
            <li>Transmit any material that is harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable</li>
            <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity</li>
            <li>Interfere with or disrupt the services or servers or networks connected to the services</li>
            <li>Collect or store personal data about other users without their consent</li>
          </ul>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[4] = el}>
          <h2>5. Intellectual Property Rights</h2>
          <p>
            The Forcify AI platform, including all content, features, and functionality, is owned by Forcify AI and is protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
          </p>
          <p>
            You may not copy, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our platform, except as follows:
          </p>
          <ul>
            <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials</li>
            <li>You may store files that are automatically cached by your Web browser for display enhancement purposes</li>
            <li>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use</li>
          </ul>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[5] = el}>
          <h2>6. Payment Terms</h2>
          <p>
            Certain services offered by Forcify AI may require payment. By subscribing to a paid service, you agree to pay all fees associated with the service you select.
          </p>
          <p>
            We may change our fees at any time by posting the changes on the site or by notifying you directly. If you do not cancel your subscription before the changes take effect, you will be responsible for the new fees.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[6] = el}>
          <h2>7. Limitation of Liability</h2>
          <p>
            In no event shall Forcify AI, its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
          </p>
          <ul>
            <li>Your access to or use of or inability to access or use the services</li>
            <li>Any conduct or content of any third party on the services</li>
            <li>Any content obtained from the services</li>
            <li>Unauthorized access, use, or alteration of your transmissions or content</li>
          </ul>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[7] = el}>
          <h2>8. Termination</h2>
          <p>
            We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
          </p>
          <p>
            If you wish to terminate your account, you may simply discontinue using the service or contact us to request account deletion.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[8] = el}>
          <h2>9. Changes to Terms</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          </p>
          <p>
            By continuing to access or use our service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the service.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[9] = el}>
          <h2>10. Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the laws of Lithuania, without regard to its conflict of law provisions.
          </p>
          <p>
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.
          </p>
        </div>

        <div className="policy-section" ref={el => sectionRefs.current[10] = el}>
          <h2>11. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at:
            <br />
            <a href="mailto:legal@forcify.ai">legal@forcify.ai</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService; 